import { EmailSubscription, ErrorBoundary, Head } from "@/components";
import { gtmHomepage } from "@jog/shared";
import { asText } from "@prismicio/helpers";
import { useEffect } from "react";
import { SliceRender } from "./Slice";
export const Home = ({ prismic, paths, }) => {
    useEffect(() => {
        gtmHomepage();
    }, []);
    return (<ErrorBoundary system="home">
            <Head isHomePage alternate={paths} title={asText(prismic.meta_tag_title)} robots={asText(prismic.meta_tag_robots)} description={asText(prismic.meta_tag_description)}/>
            <SliceRender slices={prismic.body}/>
            <EmailSubscription />
        </ErrorBoundary>);
};
